import { Header } from "../../components/header/Header"
import { Button } from "../../components/button/Button"
import { useLocation, useParams } from "wouter"
import { useForm } from "react-hook-form"
import { Form } from "@/components/ui/form"
import { InputField } from "@/components/ui/form/InputField"
import styled from "styled-components"
import { userApi } from "@/fetch/api/user"
import { toast } from "react-toastify"

const StyledForm = styled.form`
  max-width: 750px;
  margin: 0 auto;
  gap: 8px;
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`

const Title = styled.h1`
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.02em;
  text-align: left;
`

const StyledButton = styled(Button)`
  align-self: end;
  margin-top: 8px;
`
const PasswordInfoBlock = styled.div`
  background: var(--light-iris);
  border-radius: 8px;
  padding: 8px;

  ul {
    list-style: disc;
    padding-left: 20px;
  }
`

type FormInputs = {
  name: string
  username: string
  surname: string
  password: string
  repeatPassword: string
}

export const SignupComponent = () => {
  const { id } = useParams()
  const [, setLocation] = useLocation()

  const form = useForm<FormInputs>()

  const onSubmit = async (values: FormInputs) => {
    if (!id) {
      // TODO: should assert? impossible case
      return
    }

    const { password, repeatPassword, name, surname, username } = values
    let hasError = false
    if (!password || password !== repeatPassword) {
      form.setError("password", { type: "required" })
      form.setError("repeatPassword", { type: "required" })
      hasError = true
      // TODO:invalid password case
    } else {
      if (
        !/[A-Z]/.test(password) ||
        !/[0-9]/.test(password) ||
        !/[a-z]/.test(password) ||
        password.length < 8
      ) {
        form.setError("password", { type: "required" })
        form.setError("repeatPassword", { type: "required" })
        hasError = true
      }
    }

    if (!username) {
      form.setError("username", { type: "required" })
      hasError = true
    }

    // eslint-disable-next-line no-useless-escape
    if (/[^a-z0-9\.\-]/.test(username)) {
      form.setError("username", { type: "required" })
      hasError = true
    }

    if (!name) {
      form.setError("name", { type: "required" })
      hasError = true
    }

    if (!surname) {
      form.setError("surname", { type: "required" })
      hasError = true
    }

    if (hasError) {
      return
    }

    try {
      await userApi.signUp(
        { username, name: `${name} ${surname}`, password },
        id,
      )
      setLocation("/")
    } catch (err) {
      toast("Darbība nav izdevuises, lūdzu mēģiniet vēlreiz", { type: "error" })
    }
  }

  const psw = form.watch().password
  const pswHasNumber = /[0-9]/.test(psw)
  const pswHasLowercase = /[a-z]/.test(psw)
  const pswHasUppercase = /[A-Z]/.test(psw)

  return (
    <div>
      <Header hideMenu />
      <Form {...form}>
        <StyledForm onSubmit={form.handleSubmit(onSubmit)}>
          <Title>Pabeigt reģistrāciju</Title>
          <InputField
            control={form.control}
            name="username"
            placeholder="Lietotājvārds"
            label="Lietotājvārds"
            error={form.formState.errors.username}
          />
          <Row>
            <InputField
              control={form.control}
              name="name"
              placeholder="Vārds"
              label="Vārds"
              error={form.formState.errors.name}
            />
            <InputField
              control={form.control}
              name="surname"
              placeholder="Uzvārds"
              label="Uzvārds"
              error={form.formState.errors.surname}
            />
          </Row>
          <Row>
            <InputField
              control={form.control}
              name="password"
              placeholder="Parole"
              label="Parole"
              inputProps={{ type: "password" }}
              error={form.formState.errors.password}
            />
            <InputField
              control={form.control}
              name="repeatPassword"
              placeholder="Parole atkārtoti"
              label="Parole atkārtoti"
              inputProps={{ type: "password" }}
              error={form.formState.errors.repeatPassword}
            />
          </Row>
          <PasswordInfoBlock>
            <div>Parolei ir:</div>

            <ul>
              <li>
                <div style={{ color: !psw || psw.length >= 8 ? "" : "red" }}>
                  Jābūt vismaz 8 simbolus garai
                </div>
              </li>
              <li>
                <div style={{ color: !psw || pswHasNumber ? "" : "red" }}>
                  Jāiekļauj vismaz viens cipars
                </div>
              </li>
              <li>
                <div style={{ color: !psw || pswHasLowercase ? "" : "red" }}>
                  Jāiekļauj vismaz viens mazais burts
                </div>
              </li>
              <li>
                <div style={{ color: !psw || pswHasUppercase ? "" : "red" }}>
                  Jāiekļauj vismaz viens lielais burts
                </div>
              </li>
            </ul>
          </PasswordInfoBlock>
          <StyledButton type="submit">Reģistrēties</StyledButton>
        </StyledForm>
      </Form>
    </div>
  )
}
