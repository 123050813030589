import classNames from "classnames"
import styles from "./status.module.css"

type Props = {
  status?: number
  width?: number
  height?: number
}
export const Status = ({ status, width = 24, height = 24 }: Props) => {
  return (
    <div className={classNames(styles.status, styles[`status-${status}`])}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
          fill="currentColor"
        />
      </svg>
    </div>
  )
}

const getText = (status?: number) => {
  /*

 	Status_Ready    Status = 1
	Status_Started  Status = 2
	Status_Done     Status = 3
	Status_Error    Status = 4
	Status_Stopped  Status = 5
	Status_Archived Status = 6

*/
  if (!status || status === 1) {
    return "Neuzsākts"
  }
  if (status === 2) {
    return "Uzsākts"
  }
  if (status === 3) {
    return "Pabeigts"
  }
  if (status === 4) {
    return "Kļūda"
  }
  if (status === 5) {
    return "Apturēts"
  }
  if (status === 6) {
    return "Arhivēts"
  }
}

export const StatusText = (props: Props) => {
  return (
    <div
      className={classNames(
        styles.statusItem,
        styles[`status-${props.status}`],
      )}
    >
      {getText(props.status)}
    </div>
  )
}
