import { DataTable } from "@/components/dataTable/DataTable"
import { InfoBlocks } from "@/components/infoBlock/InfoBlocks"
import { tasksApi } from "@/fetch/api/tasks"
import { useCallback, useEffect, useMemo, useState } from "react"
import { createProjectColumns } from "./createProjectColumns"
import { useWatcher } from "@/lib/state/useWatcher"
import { tenantState } from "@/store/tenant"
import { Route, Switch, useLocation } from "wouter"
import { IndividualProject } from "../AdminProjects/IndividualProject"
import { ProjectOperation } from "../AdminProjects/ProjectOperation"
import { Filters } from "../AdminTasks/Filters"
import { AddNewProject } from "./AddNewProject"

export const ProjectsTable = () => {
  const [blocks, setBlocks] = useState([])
  const [tableItems, setTableItems] = useState([])
  const [filters, setFilters] = useState<Record<string, any>>({})
  const tenant = useWatcher(tenantState)
  const [, setLocation] = useLocation()

  const init = useCallback(async () => {
    try {
      const data = await tasksApi.getProjectsViewData(filters)
      if (data.info) {
        setBlocks(data.info)
      }
      if (data.items) {
        setTableItems(data.items)
      }
    } catch (err) {
      console.log(err)
      //
    }
  }, [filters])
  useEffect(() => {
    init()
  }, [filters, init])

  const columns = useMemo(() => {
    const current = createProjectColumns(
      tenant.settings.features,
      tenant.settings.labels,
    )
    return current.filter((col) => {
      if (col.id === "timePredicted") {
        return tenant.settings.features["time"]
      }

      if (col.id === "unitsPredicted") {
        return tenant.settings.features["units"]
      }

      if (col.id === "salary" || col.id === "salaryDiff") {
        return tenant.settings.features["salary"]
      }

      if (col.id === "difficulty") {
        return tenant.settings.features["normatives"]
      }

      return true
    })
  }, [tenant.settings.features, tenant.settings.labels])

  return (
    <Switch>
      <Route path="/:id" component={IndividualProject} />
      <Route path="/:id/:projectId">
        <ProjectOperation projects={tableItems} />
      </Route>
      <Route path="/">
        <AddNewProject onAdd={init} />
        <Filters
          onFiltersChange={setFilters}
          availableFilters={["from", "to", "statuses"]}
          show
        />
        <InfoBlocks items={blocks} />
        <DataTable
          columns={columns}
          data={tableItems}
          onRowClick={(row) => setLocation(`/${row.id}`)}
        />
      </Route>
    </Switch>
  )
}
