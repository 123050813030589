import { createWatchable } from "../lib/state/createWatchable"

export type TenantFeature =
  | "time"
  | "units"
  | "salary"
  | "errors"
  | "districts"
  | "normatives"
  | "strictOrder"

export type TenantDetails = {
  id: number
  name: string
  slug: string
  settings: {
    features: Partial<Record<TenantFeature, boolean>>
    labels:
      | {
          Apakšprojekts: string
          Apakšprojekti: string
          Darbs: string
          Darbi: string
          Operācija: string
          Operācijas: string
          Projekts: string
          Projekti: string
        }
      | Record<string, string>
  }
}

export const tenantState = createWatchable<TenantDetails>({
  id: 0,
  name: "",
  slug: "",
  settings: {
    features: {},
    labels: {
      Apakšprojekts: "",
      Apakšprojekti: "",
      Darbs: "",
      Darbi: "",
      Operācija: "",
      Operācijas: "",
      Projekts: "",
      Projekti: "",
    },
  },
})
