import { useEffect, useState } from "react"
import { useLocation } from "wouter"
import { Project } from "../../../../types"
import { StatusItem } from "../../../components/statusItem/StatusItem"
import { getUserProjects } from "../../../fetch/projects"
import styles from "./tasks.module.css"
import { useWatcher } from "../../../lib/state/useWatcher"
import { tenantState } from "../../../store/tenant"
import { EmptyScreen } from "../../../components/emptyScreen/EmptyScreen"

export const Tasks = () => {
  const {
    settings: { features },
  } = useWatcher(tenantState)
  const [, setLocation] = useLocation()
  const [loaded, setLoaded] = useState(false)
  const [projects, setProjects] = useState<
    { title: string; items: Project[] }[]
  >([])
  useEffect(() => {
    getUserProjects().then(async (res) => {
      setLoaded(true)
      const json = await res.json()
      setProjects(json)
    })
  }, [])

  const goToTask = (id: number) => {
    setLocation(`/dashboard/project/${id}`)
  }

  const isEmpty =
    loaded && !projects.filter((proj) => !!proj.items.length).length

  if (isEmpty) {
    return <EmptyScreen title="Nav aktīvu projektu" />
  }
  return (
    <div className={styles.wrap}>
      <div className={styles.title}>Mani uzdevumi</div>
      {projects.map((proj) =>
        proj.items.length ? (
          <div>
            <div className={styles.heading}>{proj.title}</div>
            {proj.items.map((item) => {
              return (
                <StatusItem
                  onClick={() => goToTask(item.id)}
                  status={item.status}
                  name={item.name}
                  subTitle={`${item.subCount} ${
                    features?.["districts"]
                      ? `Iecirk${item.subCount % 10 == 1 ? "nis" : "ņi"}`
                      : `Projekt${item.subCount % 10 == 1 ? "s" : "i"}`
                  }`}
                />
              )
            })}
          </div>
        ) : null,
      )}
    </div>
  )
}
