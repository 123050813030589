import { DataTable } from "@/components/dataTable/DataTable"
import { InfoBlocks } from "@/components/infoBlock/InfoBlocks"
import { tasksApi } from "@/fetch/api/tasks"
import { useCallback, useEffect, useMemo, useState } from "react"
import { createColumns } from "./columns"
import { useWatcher } from "@/lib/state/useWatcher"
import { tenantState } from "@/store/tenant"
import { Task } from "src/types"
import { ConfirmTask } from "../AdminTasks/ConfirmTask"
import { toast } from "react-toastify"
import { useEffectOnce } from "react-use"
import { Portal } from "@/components/portal/Portal"
import { Export } from "../AdminTasks/Export"
import { Filters } from "../AdminTasks/Filters"
import queryString from "query-string"
import { useLocation, useSearch } from "wouter"

export const TasksTable = () => {
  const [blocks, setBlocks] = useState([])
  const [tableItems, setTableItems] = useState([])
  const tenant = useWatcher(tenantState)
  const [selectedRow, setSelectedRow] = useState<undefined | number>()
  const [location, setLocation] = useLocation()
  const search = useSearch()
  const initialFilters = queryString.parse(search)
  const [filters, setFilters] = useState<Record<string, any>>(initialFilters)

  const init = useCallback(async () => {
    try {
      const data = await tasksApi.getTasks(filters)
      if (data.info) {
        setBlocks(data.info)
      }
      if (data.items) {
        setTableItems(data.items)
      }
    } catch (err) {
      console.log(err)
      //
    }
  }, [filters])

  useEffect(() => {
    console.log("change filters")
    init()
  }, [filters, init])

  const onConfirmTask = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>, id: number) => {
      e.preventDefault()
      e.stopPropagation()
      try {
        await tasksApi.confirmTask(id, { status: 1 })
        toast("Uzdevums veiksmīgi apstiprināts!", { type: "success" })
        init()
      } catch (err) {
        console.log(err)
        toast("Neizdevās apstiprināt uzdevumu, mēģiniet vēlreiz", {
          type: "error",
        })
      }
    },
    [init],
  )

  const handleRowClick = (row: Task) => {
    setSelectedRow(row.id)
  }

  const selectedTask = useMemo(() => {
    if (selectedRow) {
      return tableItems.find(({ id }) => id === selectedRow)
    }
    return undefined
  }, [selectedRow, tableItems])

  const columns = useMemo(
    () => createColumns(tenant.settings.features, onConfirmTask),
    [tenant.settings.features, onConfirmTask],
  )
  const handleFiltersChange = (nextFilters: Record<string, any>) => {
    const str = queryString.stringify(nextFilters)
    setLocation(location + "?" + str)

    setFilters(nextFilters)
  }

  return (
    <>
      <Portal id="tabs-right">
        <Export />
      </Portal>
      <Filters
        onFiltersChange={handleFiltersChange}
        availableFilters={[
          "from",
          "to",
          "workers",
          "projects",
          "status",
          "districts",
        ]}
        show={!selectedTask}
        initialFilters={filters}
      />
      <ConfirmTask
        task={selectedTask}
        onClose={(forceReload) => {
          setSelectedRow(undefined)
          if (forceReload) {
            init()
          }
        }}
      />
      <InfoBlocks items={blocks} />
      <DataTable
        onRowClick={handleRowClick}
        columns={columns}
        data={tableItems}
      />
    </>
  )
}
