import "./button.css"
import { ButtonHTMLAttributes, PropsWithChildren } from "react"
import classNames from "classnames"

export const Button = ({
  children,
  ...props
}: PropsWithChildren &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: "inline" | "secondary"
  }) => {
  return (
    <button
      {...props}
      className={classNames("btn", props.variant, props.className)}
    >
      {children}
    </button>
  )
}
