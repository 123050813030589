import { doFetch } from "./fetch"

class User {
  login = (body: { username: string; password: string }) => {
    return doFetch("/login", {
      body,
      method: "POST",
    })
  }
  getSelf = () => {
    return doFetch("/api/self")
  }
  signUp = (
    body: { name: string; username: string; password: string },
    id: string,
  ) => {
    return doFetch(`/api/signup/${id}`, { method: "POST", body })
  }
}

export const userApi = new User()
