import { Status } from "@/components/status/Status"
import { formatTimeDelta } from "@/lib/formatTimeDelta"
import { TenantDetails, TenantFeature } from "@/store/tenant"
import { ColumnDef } from "@tanstack/react-table"
import { Project } from "src/types"

export const createProjectColumns = (
  features: Partial<Record<TenantFeature, boolean>>,
  labels: TenantDetails["settings"]["labels"] = {},
): ColumnDef<Project>[] => {
  return [
    {
      id: "status",
      accessorKey: "status",
      header: "Status",
      cell: ({ row }) => {
        const og = row.original
        const progress = features["units"] ? og.progress : og.timeProgress
        return (
          <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
            <Status status={og.status} width={16} height={16} />
            <span style={{ fontSize: 14, fontWeight: 500 }}>{progress}%</span>
          </div>
        )
      },
    },
    {
      id: "name",
      accessorKey: "name",
      header: labels.Projekts,
      cell: ({ row }) => (
        <span style={{ fontWeight: 500 }}>
          {row.original.name}
          <span style={{ color: "var(--text-secondary)" }}>
            {" "}
            ({row.original.code})
          </span>
        </span>
      ),
    },
    {
      id: "difficulty",
      accessorKey: "difficulty",
      header: "Normatīvi",
      cell: ({ row }) => {
        const og = row.original
        return (
          <span
            style={{ color: og.difficulty > 100 ? "var(--error)" : undefined }}
          >
            {og.difficulty}%
          </span>
        )
      },
    },
    {
      id: "subprojectCount",
      accessorKey: "subprojectCount",
      header: labels["Apakšprojekti"],
      cell: ({ row }) => {
        return <>{row.original.subprojectCount} Iecirkņi</>
      },
    },
    {
      id: "timePredicted",
      accessorKey: "timePredicted",
      header: "Laiks",
      cell: ({ row }) => {
        const og = row.original
        const value = og.timePredicted
        const timeUsed = og.timeUsed
        if (value && timeUsed != null) {
          const moreTimeSpentThanPredicted = timeUsed > value
          return (
            <div>
              <span style={{ color: moreTimeSpentThanPredicted ? "red" : "" }}>
                {timeUsed ? formatTimeDelta(timeUsed) : "-"}
              </span>
              <span style={{ color: "var(--text-secondary)" }}>
                {` / `}
                {formatTimeDelta(value)}
              </span>
            </div>
          )
        }
        return "-"
      },
    },

    {
      id: "unitsPredicted",
      accessorKey: "unitsPredicted",
      header: "Vienības",
      cell: ({ row }) => {
        const value = row.original.unitsPredicted
        const unitsUsed = row.original.unitsUsed
        if (unitsUsed != null) {
          const moreUnitsDoneThanPredicted = unitsUsed > value
          return (
            <div>
              <span style={{ color: moreUnitsDoneThanPredicted ? "red" : "" }}>
                {unitsUsed}
              </span>
              <span style={{ color: "var(--text-secondary)" }}>
                {` / `}
                {value}
              </span>
            </div>
          )
        }
        return "-"
      },
    },
    {
      id: "salaryDiff",
      accessorKey: "salaryDiff",
      header: "Zaudējumi",
      cell: ({ row }) => {
        const v = row.original.salaryDiff
        const val = v ? `${v / 100}€` : "-"

        return <span style={{ color: v < 0 ? "var(--error)" : "" }}>{val}</span>
      },
    },
    {
      id: "salary",
      accessorKey: "salary",
      header: "Summa",
      cell: ({ row }) => {
        const v = row.original.salaryUsed
        return v ? `${v / 100}€` : "-"
      },
    },
  ]
}
