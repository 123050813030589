import styled from "styled-components"

const Root = styled.div`
  display: flex;
  gap: 8px;
`

const Item = styled.div`
  border: 1px solid var(--accent);
  border-radius: 8px;
  padding: 24px 16px;
  flex-grow: 1;
  line-height: normal;
`

const Title = styled.div`
  font-size: 36px;
  font-weight: 700;
`

const Sub = styled.div`
  font-size: 12px;
  color: var(--text-secondary);
`

type Props = {
  items: {
    title: string | number
    description: string
  }[]
}

export const InfoBlocks = ({ items }: Props) => {
  return (
    <Root>
      {items.map(({ title, description }, i) => {
        return (
          <Item key={i}>
            <Title>{title}</Title>
            <Sub>{description}</Sub>
          </Item>
        )
      })}
    </Root>
  )
}
