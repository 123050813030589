import { Button } from "@/components/button/Button"
import { Portal } from "@/components/portal/Portal"
import { Sidepanel } from "@/components/sidepanel/Sidepanel"
import { Form } from "@/components/ui/form"
import { DropdownField } from "@/components/ui/form/DropdownField"
import { InputField } from "@/components/ui/form/InputField"
import { districtsApi } from "@/fetch/api/districts"
import { tasksApi } from "@/fetch/api/tasks"
import { useWatcher } from "@/lib/state/useWatcher"
import { tenantState } from "@/store/tenant"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { District, Project } from "src/types"
import styled from "styled-components"

const ButtonRow = styled.div`
  display: flex;
  margin: 10px;
  justify-content: flex-end;
  gap: 8px;
`

const Title = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 20px;
`

type ProjectForm = Partial<Project> & {
  districtId?: number[]
  parentId?: number
}

export const AddNewProject = ({
  onAdd,
  id,
}: {
  onAdd: () => void
  id?: number
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [districts, setDistricts] = useState<District[]>([])
  const form = useForm<ProjectForm>()
  const tenant = useWatcher(tenantState)

  useEffect(() => {
    if (tenant.settings.features.districts) {
      districtsApi.getDistricts().then((res) => {
        setDistricts(res)
      })
    }
  }, [tenant.settings.features])

  const onSubmit = async (values: ProjectForm) => {
    let body = { ...values, districtId: undefined }
    const districtId = values.districtId?.at(0)
    // in case district is selected, we treat it as a
    // district, not a project
    if (districtId) {
      const district = districts.find(({ id }) => id === districtId)!
      body.name = district.name
      body.workers = district.workers
      body.shift = district.shift
      body.concurrentShifts = district.concurrentShifts
      body.shiftsPerDay = district.shiftsPerDay
    }
    try {
      await tasksApi.createProject({ ...body, districtId, parentId: id })
      onAdd()
      setIsOpen(false)
      form.reset()
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <>
      <Portal id="tabs-right">
        <Button onClick={() => setIsOpen(true)}>Pievienot</Button>
      </Portal>
      <Sidepanel open={isOpen} onClose={() => setIsOpen(false)}>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Title>Pievienot jaunu projektu</Title>

            {id && tenant.settings.features["districts"] ? (
              <DropdownField
                control={form.control}
                name="districtId"
                placeholder="Iecirknis"
                label="Iecirknis"
                options={districts}
              />
            ) : (
              <InputField
                control={form.control}
                name="name"
                placeholder="Nosaukums"
                label="Nosaukums"
              />
            )}
            <InputField
              control={form.control}
              name="code"
              placeholder="Kods"
              label="Kods"
            />
            <ButtonRow>
              <Button
                variant="secondary"
                type="button"
                onClick={() => setIsOpen(false)}
              >
                Atcelt
              </Button>
              <Button type="submit">Pievienot</Button>
            </ButtonRow>
          </form>
        </Form>
      </Sidepanel>
    </>
  )
}
