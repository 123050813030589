import { useEffect, useState } from "react"
import { Button } from "../../components/button/Button"
import { authState, userState } from "../../store/user"
import { useWatcher } from "../../lib/state/useWatcher"
import { toast } from "react-toastify"
import { useForm } from "react-hook-form"
import { Input } from "@/components/ui/input"
import { Form, FormField, FormItem } from "@/components/ui/form"
import styled from "styled-components"
import { userApi } from "@/fetch/api/user"

const Root = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
`

const Box = styled.form`
  border: 1px solid var(--accent);
  border-radius: 8px;
  padding: 24px 16px;
  min-width: 400px;
  gap: 8px;
  display: flex;
  flex-direction: column;
`

type FormInputs = {
  username: string
  password: string
}

export const Login = () => {
  const auth = useWatcher(authState)
  const [loading, setLoading] = useState(true)
  const form = useForm<FormInputs>()

  const initUser = () => {
    userApi
      .getSelf()
      .then(async (res) => {
        authState.update({ isAuth: true })
        userState.update(res)
      })
      .catch(() => {
        // ignore err
        // means user is un-auth
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const onSubmit = async ({ username, password }: FormInputs) => {
    try {
      await userApi.login({ username, password })
      authState.update({ isAuth: true })
      initUser()
    } catch (err) {
      form.setError("username", { type: "required" })
      toast("Lietotājvārds vai parole nav pareizi", { type: "error" })
    }
  }

  useEffect(() => {
    if (auth.isAuth) {
      return
    }
    initUser()
    // on initial load, check myself
  }, [auth])

  if (loading) {
    return null
  }
  return (
    <Form {...form}>
      <Root>
        <img src="/assets/logo.svg" alt="productionline.io" />
        <Box onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="username"
            render={({ field }) => (
              <FormItem>
                <Input
                  {...field}
                  placeholder="Lietotājvārds"
                  className={
                    form.formState.errors.username
                      ? "border-[var(--error)]"
                      : ""
                  }
                />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <Input
                  {...field}
                  placeholder="Parole"
                  type="password"
                  className={
                    form.formState.errors.username
                      ? "border-[var(--error)]"
                      : ""
                  }
                />
              </FormItem>
            )}
          />
          <Button type="submit">Ienākt</Button>
        </Box>
      </Root>
    </Form>
  )
}
