import { Tab, Tabs } from "@/components/tabs/Tabs"
import { useWatcher } from "@/lib/state/useWatcher"
import { tenantState } from "@/store/tenant"
import styled from "styled-components"
import { TasksTable } from "./tasksTable/tasksTable"
import { AdminOperations } from "./AdminOperations/AdminOperations"
import { AdminDistricts } from "./AdminDistricts/AdminDistricts"
import { CreateUser } from "./AdminUsers/CreateUser"
import { AdminUsers } from "./AdminUsers/AdminUsers"
import { AdminErrors } from "./AdminErrors/AdminErrors"
import { AdminImport } from "./AdminImport/AdminImport"
import { Redirect, Route, Switch } from "wouter"
import { ProjectsTable } from "./projects/ProjectsTable"

const getTabs = (useSeries: boolean): Tab[] => [
  {
    id: "/tasks",
    title: "Uzdevumi",
    Component: TasksTable,
  },
  {
    id: "/projects",
    title: useSeries ? "Sērijas" : "Projekti",
    Component: ProjectsTable,
  },
  {
    id: "/process",
    title: useSeries ? "Tirgi" : "Procesi",
    Component: AdminOperations,
  },
  {
    id: "/districts",
    title: "Iecirkņi",
    Component: AdminDistricts,
    features: ["districts"],
  },
  {
    id: "/employees/create",
    title: "Darbinieki",
    Component: CreateUser,
    hideInTabs: true,
  },
  {
    id: "/employees",
    title: "Darbinieki",
    Component: AdminUsers,
  },
  {
    id: "/faults",
    title: "Kļūdas",
    Component: AdminErrors,
  },
  {
    id: "/import",
    title: "Importēt",
    Component: AdminImport,
  },
]

const Root = styled.div`
  padding: 0 24px 60px;
`

export const AdminRoot = () => {
  const tenant = useWatcher(tenantState)

  const tabs = getTabs(tenant.slug === "bitus")
  return (
    <Root>
      <Tabs tabs={tabs} />

      <Switch>
        {tabs.map(({ id, Component, title }) => (
          <Route path={id} key={id} nest>
            {Component ? <Component /> : title}
          </Route>
        ))}
        <Redirect to="/tasks" />
      </Switch>
    </Root>
  )
}
