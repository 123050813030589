import { Button } from "@/components/button/Button"
import { Warning } from "@/components/icons/Warning"
import { formatTimeDelta } from "@/lib/formatTimeDelta"
import { TenantFeature } from "@/store/tenant"
import { ColumnDef } from "@tanstack/react-table"
import { Task } from "src/types"
import { Link } from "wouter"

export const createColumns = (
  features: Partial<Record<TenantFeature, boolean>>,
  onConfirmTask: (
    e: React.MouseEvent<HTMLButtonElement>,
    taskId: number,
  ) => void,
): ColumnDef<Task>[] => {
  return [
    {
      accessorKey: "workerName",
      header: "Darbinieks",
      cell: ({ row }) => {
        const og = row.original
        const created = og.createdAt
          ? new Intl.DateTimeFormat("lv", {
              dateStyle: "short",
              timeStyle: "short",
            }).format(new Date(og.createdAt))
          : "-"

        return (
          <>
            <div>{og.workerName}</div>
            <div className="text-[var(--text-secondary)] text-sm">
              {created}
            </div>
          </>
        )
      },
    },
    {
      accessorKey: "projectName",
      header: "Sērija",
      cell: ({ row }) => {
        const og = row.original
        return (
          <span className="flex items-center gap-2">
            {og.defect ? <Warning width={13} height={12} /> : null}
            <Link to={`/../projects/${og.projectId}/${og.subProjectId}`}>
              {og.projectName} / {og.subProjectName} / {og.operationName}
            </Link>
          </span>
        )
      },
    },
    {
      accessorKey: "timeUsed",
      header: "Laiks",
      cell: ({ row }) => {
        const og = row.original
        const used = og.timeUsed ? formatTimeDelta(og.timeUsed) : "-"
        const showNormative = features["normatives"] && features["units"]
        const expectedTime =
          showNormative && Math.ceil(og.normative * og.unitsUsed)

        const efficiency =
          expectedTime &&
          Math.max(0, Math.round((expectedTime / og.timeUsed) * 100))
        return (
          <div>
            {used}
            {` `}
            {showNormative && expectedTime && efficiency ? (
              <>
                ({formatTimeDelta(expectedTime)})
                <div
                  className={`text-sm ${efficiency < 100 ? "text-[var(--error)]" : "text-[var(--text-secondary)]"} `}
                >
                  {efficiency}%
                </div>
              </>
            ) : null}
          </div>
        )
      },
    },
    {
      accessorKey: "unitsUsed",
      header: "Vienības",
      cell: ({ row }) => {
        const og = row.original
        return (
          <>
            {og.unitsUsed}{" "}
            {og.opUnitsUsed && (
              <span
                className={`${og.unitsPredicted < og.opUnitsUsed ? "text-[var(--error)]" : "text-[var(--text-secondary)]"}`}
              >
                ({og.opUnitsUsed}/{og.unitsPredicted})
              </span>
            )}
          </>
        )
      },
    },
    {
      accessorKey: "comment",
      header: "Piezīmes",
      cell: ({ row }) => {
        const og = row.original
        return og.comment ? (
          <span className="block max-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap">
            {og.comment}
          </span>
        ) : (
          "-"
        )
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ row }) => {
        const og = row.original
        if (og.status === 1) {
          return "Apstiprināts"
        }
        return (
          <Button onClick={(e) => onConfirmTask(e, og.id)}>Apstiprināt</Button>
        )
      },
    },
  ]
}
