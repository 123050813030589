import classNames from "classnames"
import { Input } from "../../../components/form/input"
import styles from "./adminusers.module.css"
import { Button } from "../../../components/button/Button"
import { useEffect, useState } from "react"
import { createUser } from "../../../fetch/users"
import { useLocation } from "wouter"
import { useWatcher } from "../../../lib/state/useWatcher"
import { TenantFeature, tenantState } from "../../../store/tenant"
import { District, PermissionItem, UserRole, Worker } from "../../../../types"
import { Select } from "../../../components/form/select"
import { getDistricts } from "../../../fetch/districts"
import { Combobox } from "@/components/combobox/Combobox"

export const CreateUser = () => {
  const {
    settings: { features },
  } = useWatcher(tenantState)
  const [, setLocation] = useLocation()
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [surname, setSurname] = useState("")
  const [email, setEmail] = useState("")
  const [role, setRole] = useState(1)
  const [permissions, setPermissions] = useState<Worker["permissions"]>({
    projects: [],
    opGroups: [],
    districts: [],
  })
  const [districts, setDistricts] = useState<District[]>([])

  const init = () => {
    getDistricts().then(async (res) => {
      const json = await res.json()
      if (json) {
        setDistricts(json)
      }
    })
  }
  useEffect(() => {
    init()
  }, [])

  const handleCreateUser = async () => {
    setLoading(true)
    try {
      await createUser({
        name: `${name} ${surname}`,
        email,
        role,
        permissions,
      })
      setLocation("..")
    } catch (err) {
      setLoading(false)
    }
  }
  return (
    <div className={styles.root}>
      <h1 className={styles.addTitle}>Pievienot darbinieku</h1>
      <div className={styles.row}>
        <Input
          placeholder="Vārds"
          label="Vārds"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Input
          placeholder="Uzvārds"
          label="Uzvārds"
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
        />
      </div>
      <div className={styles.row}>
        <Input
          placeholder="E-pasts"
          label="E-pasts"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
        />
      </div>
      <div className={styles.row}>
        <Input
          label="Administrators"
          type="checkbox"
          checked={role === UserRole.Admin}
          onChange={(e) =>
            setRole(e.target.checked ? UserRole.Admin : UserRole.User)
          }
        />
      </div>
      {role !== UserRole.Admin && features?.["districts"] && (
        <div style={{ marginBottom: 24 }}>
          <label className={styles.label}>Piekļuve iecirkņiem</label>
          <Combobox
            options={[
              { id: undefined, name: "Neviens" },
              ...districts.map(({ name, id }) => ({
                id,
                name,
              })),
            ]}
            onChange={(options) => {
              const districtsPermisisons: PermissionItem[] = []
              options.forEach((value) => {
                const district = districts.find(({ id }) => id === value)
                if (!district) return null
                districtsPermisisons.push({
                  id: district.id,
                  name: district.name,
                })
              })
              setPermissions({
                ...permissions,
                districts: districtsPermisisons,
              })
            }}
          />
        </div>
      )}
      <div className={classNames(styles.row, styles.submit)}>
        <Button disabled={loading} onClick={handleCreateUser}>
          Saglabāt
        </Button>
      </div>
    </div>
  )
}
