import { Control, FieldError, FieldPath, FieldValues } from "react-hook-form"
import { FormField, FormItem, FormLabel } from "../form"
import { ComponentProps } from "react"
import { Combobox } from "@/components/combobox/Combobox"

type Props<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
> = {
  control: Control<T>
  name: TName
  placeholder?: string
  label?: string
  error?: FieldError
  options: ComponentProps<typeof Combobox>["options"]
}
export function DropdownField<T extends FieldValues = FieldValues>({
  control,
  name,
  label,
  options,
}: Props<T>) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          {label && <FormLabel>{label}</FormLabel>}
          <Combobox {...field} options={options} single />
        </FormItem>
      )}
    />
  )
}
