import { Route, Switch } from "wouter"
import { Login } from "./Login"
import { PropsWithChildren } from "react"
import { useWatcher } from "@/lib/state/useWatcher"
import { authState } from "@/store/user"
import { SignupComponent } from "./Signup"

export const AuthWrapper = ({ children }: PropsWithChildren<{}>) => {
  const auth = useWatcher(authState)

  if (auth.isAuth) {
    return children
  }
  return (
    <Switch>
      <Route path="/signup/:id" component={SignupComponent} />
      <Route component={Login} />
    </Switch>
  )
}
