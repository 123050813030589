import React, { useEffect, useState } from "react"
import classNames from "classnames"
import { District, PermissionItem, UserRole, Worker } from "../../../../types"
import { Button } from "../../../components/button/Button"
import panelstyles from "../AdminTasks/sidepanel.module.css"
import styles from "./adminusers.module.css"
import { getDistricts } from "../../../fetch/districts"
import { Input } from "../../../components/form/input"
import { DeleteIcon, IconButton } from "../../../components/button/IconButton"
import { TenantDetails, TenantFeature } from "../../../store/tenant"
import { Select } from "../../../components/form/select"
import { deleteUser, updateUser } from "../../../fetch/users"
import { Label } from "@/components/ui/label"
import { Combobox } from "@/components/combobox/Combobox"

type Props = {
  data?: Worker
  onClose: (updated?: boolean) => void
  setEditValue<k extends keyof Worker>(field: k, value: Worker[k]): void
  features?: TenantDetails["settings"]["features"]
}
export const EditUser = ({ data, onClose, setEditValue, features }: Props) => {
  const [districts, setDistricts] = useState<District[]>([])

  const init = () => {
    getDistricts().then(async (res) => {
      const json = await res.json()
      if (json) {
        setDistricts(json)
      }
    })
  }
  useEffect(() => {
    data && init()
  }, [data])

  const onDelete = (id: number) => {
    deleteUser(id).then(() => {
      onClose(true)
    })
  }

  const handleEditSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (data != null) {
    }
    if (data?.id == null) {
      return
    } else {
      updateUser(data.id, data).then(() => {
        onClose(true)
      })
    }
  }

  return (
    <div
      className={classNames(
        panelstyles.sidepanel,
        data ? panelstyles.open : "",
      )}
    >
      {data?.id ? (
        <div className={classNames(panelstyles.wrap, panelstyles.flexCol)}>
          <div className={panelstyles.close}>
            <Button variant="inline" onClick={() => onClose()}>
              Aizvērt
            </Button>
          </div>

          <form
            onSubmit={handleEditSubmit}
            className="district-sidebar-content"
          >
            <div className={styles.root}>
              <div className={styles.row}>
                <Input
                  type="text"
                  label="Vārds / uzvārds"
                  onChange={(e) => setEditValue("name", e.target.value)}
                  value={data.name}
                />
              </div>
              <div className={styles.row}>
                <Input
                  type="text"
                  label="Lietotājvārds"
                  disabled={true}
                  value={data.username}
                />
              </div>
              <div className={styles.row}>
                <Input
                  label="E-pasts"
                  value={data.email}
                  onChange={(e) => setEditValue("email", e.target.value)}
                  type="email"
                />
              </div>
              <div className={styles.row}>
                <Input
                  label="Administrators"
                  type="checkbox"
                  checked={data.role === UserRole.Admin}
                  onChange={(e) =>
                    setEditValue(
                      "role",
                      e.target.checked ? UserRole.Admin : UserRole.User,
                    )
                  }
                />
              </div>
              {data?.role === UserRole.User && features?.["districts"] && (
                <div>
                  <label className={styles.label}>Piekļuve iecirkņiem</label>
                  <Combobox
                    selected={
                      data.permissions?.districts?.map(({ id }) => `${id}`) ??
                      []
                    }
                    options={[
                      { id: undefined, name: "Neviens" },
                      ...districts.map(({ name, id }) => ({
                        id,
                        name,
                      })),
                    ]}
                    onChange={(options) => {
                      if (!data) return
                      const districtsPermisisons: PermissionItem[] = []
                      options.forEach((value) => {
                        const district = districts.find(
                          ({ id }) => id === value,
                        )
                        if (!district) return null
                        districtsPermisisons.push({
                          id: district.id,
                          name: district.name,
                        })
                      })
                      if (!options.length) {
                      }
                      setEditValue("permissions", {
                        ...data.permissions,
                        districts: districtsPermisisons,
                      })
                    }}
                  />
                </div>
              )}
            </div>
          </form>

          <div className={panelstyles.buttons}>
            {data?.id != null ? (
              <IconButton onClick={() => onDelete(data.id as number)}>
                {DeleteIcon}
              </IconButton>
            ) : null}
            <div style={{ display: "flex", gap: 8 }}>
              <Button variant="secondary" onClick={() => onClose()}>
                Atcelt
              </Button>
              <Button
                onClick={handleEditSubmit}
                disabled={(data?.name?.trim?.().length ?? 0) < 1}
              >
                Saglabāt
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}
