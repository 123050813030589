import { FormEvent, useEffect, useState } from "react"
import { EditIcon, IconButton } from "../../../components/button/IconButton"
import { Sidepanel } from "../../../components/sidepanel/Sidepanel"
import { Input } from "../../../components/form/input"
import { Task } from "../../../../types"
import { Button } from "../../../components/button/Button"
import { updateGeneralTask } from "../../../fetch/projects"
import { toast } from "react-toastify"
import { TenantDetails } from "@/store/tenant"

export const EditTask = ({
  task,
  onSave,
  features,
}: {
  task: Task
  onSave?: () => void
  features?: TenantDetails["settings"]["features"]
}) => {
  const [open, setOpen] = useState(false)
  const hasFeatureUnits = features?.["units"]
  const hasFeatureTime = features?.["time"]
  const hasFeatureNormatives = features?.["normatives"]
  const hasFeatureSalary = features?.["salary"]

  const [errors, setErrors] = useState<Record<string, boolean>>({})
  const [time, setTime] = useState<{ hours: number; minutes: number }>({
    hours: Math.floor((task?.timePredicted ?? 0) / 60),
    minutes: (task?.timePredicted ?? 0) % 60,
  })
  const [units, setUnits] = useState(task.unitsPredicted)
  const [salary, setSalary] = useState(task.salaryPredicted)
  const [normative, setNormative] = useState(task.normative)

  useEffect(() => {
    const values = {
      time,
      units,
      salary,
      normative,
    }

    const nextErrors: Record<string, boolean> = {}
    Object.keys(values).forEach((key) => {
      const value = values[key as keyof typeof values]
      if (value || value === 0) {
        nextErrors[key] = false
      }
    })
    setErrors((err) => ({ ...err, ...nextErrors }))
  }, [time, units, salary, normative])

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    const nextErrors: Record<string, boolean> = {}
    // if (!time) {
    //   nextErrors.time = true
    // }

    if (hasFeatureUnits && !units && units !== 0) {
      nextErrors.units = true
    }

    if (hasFeatureSalary && !salary && salary !== 0) {
      nextErrors.salary = true
    }

    if (hasFeatureNormatives && !normative && normative !== 0) {
      nextErrors.normative = true
    }
    //
    if (Object.keys(nextErrors).length) {
      setErrors(nextErrors)
      return
    }

    try {
      const data: Partial<Task> = {}
      if (hasFeatureUnits) {
        data.unitsPredicted = units
      }
      if (hasFeatureTime) {
        data.timePredicted = time.hours * 60 + time.minutes
      }
      if (hasFeatureNormatives) {
        data.normative = normative
      }
      if (hasFeatureSalary) {
        data.salaryPredicted = salary
      }
      if (Object.keys(data).length > 0) {
        await updateGeneralTask(task.id, data)
      }
      toast("Veiksmīgi izlabots", { type: "success" })
      onSave?.()
      setOpen(false)
    } catch (err) {
      toast("Labošana nesekmīga, lūdzu mēģiniet vēlreiz", { type: "error" })
    }
  }

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setOpen(true)
        }}
      >
        {EditIcon}
      </IconButton>
      <Sidepanel open={open} onClose={() => setOpen(false)}>
        <form onSubmit={handleSubmit}>
          <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            {hasFeatureUnits && (
              <Input
                label={"Paredzētās Vienības"}
                value={units}
                type="number"
                onChange={(e) => setUnits(+e.target.value)}
                error={errors.units}
              />
            )}

            {hasFeatureTime && (
              <div className="flex gap-2">
                <Input
                  label="Stundas"
                  value={time.hours}
                  onChange={(e) =>
                    setTime({
                      ...time,
                      hours: +e.target.value,
                    })
                  }
                />
                <Input
                  label="Minūtes"
                  value={time.minutes}
                  onChange={(e) =>
                    setTime({
                      ...time,
                      minutes: +e.target.value,
                    })
                  }
                />
              </div>
            )}

            {hasFeatureNormatives && (
              <Input
                label={"Normatīvs"}
                type="number"
                value={normative}
                onChange={(e) => setNormative(+e.target.value)}
                error={errors.normative}
              />
            )}

            {hasFeatureSalary && (
              <Input
                label={"Summa"}
                value={salary}
                type="number"
                onChange={(e) => setSalary(+e.target.value)}
                error={errors.salary}
              />
            )}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <div />
            <div style={{ display: "flex", gap: 8 }}>
              <Button
                variant="secondary"
                onClick={(e) => {
                  e.preventDefault()
                  setOpen(false)
                }}
              >
                Atcelt
              </Button>
              <Button type="submit">Saglabāt</Button>
            </div>
          </div>
        </form>
      </Sidepanel>
    </>
  )
}
