export const Warning = ({ width = 21, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 0C4.98 0 0.5 4.48 0.5 10C0.5 15.52 4.98 20 10.5 20C16.02 20 20.5 15.52 20.5 10C20.5 4.48 16.02 0 10.5 0ZM11.5 15H9.5V13H11.5V15ZM11.5 11H9.5V5H11.5V11Z"
        fill="#FF4E27"
      />
    </svg>
  )
}
