import { useCallback, useEffect, useMemo, useState } from "react"
import { Link, useParams } from "wouter"
import { Operation, Task } from "../../../../../types"
import { Button } from "../../../../components/button/Button"
import { ChevronLeft } from "../../../../components/icons/ChevronLeft"
import { StatusText } from "../../../../components/status/Status"
import { formatTimeDelta } from "../../../../lib/formatTimeDelta"
import { FinishTask } from "./FinishTask/FinishTask"
import styles from "./view-task.module.css"
import classNames from "classnames"
import { localFetch } from "../../../../fetch/localFetch"
import { toggleDone } from "../../../../fetch/projects"
import { toast } from "react-toastify"
import { HistoryItem } from "./HistoryItem"
import { TenantFeature, tenantState } from "../../../../store/tenant"
import { useWatcher } from "../../../../lib/state/useWatcher"
import { RegisterDefect } from "./RegisterDefect"
import { BreadCrumbs } from "@/components/breadcrumbs/BreadCrumbs"

export const ViewTask = () => {
  const params = useParams()
  const [projectInfo, setProjectInfo] = useState<any>()
  const [sub, setSub] = useState<any>()
  const [operation, setOperation] = useState<Operation>()
  const [isOpen, setIsOpen] = useState(false)
  const [isFinishing, setIsFinishing] = useState(false)
  const [history, setHistory] = useState<Task[]>([])
  const [taskEdit, setTaskEdit] = useState<Task | undefined>()
  const {
    settings: { features },
  } = useWatcher(tenantState)

  const init = useCallback(() => {
    if (params.projId && params.subId && params.taskId) {
      try {
        localFetch(
          `/api/views/user_projects/${params.projId}/${params.subId}/${params.taskId}`,
        ).then(async (res) => {
          if (res.ok) {
            const json = await res.json()
            setOperation(json.operation)
            setSub(json.subproject)
            setProjectInfo(json.project)
            setHistory(json.tasks)
          }
        })
      } catch (err) {
        console.log(err)
      }
    }
  }, [params])

  useEffect(() => {
    init()
  }, [init])

  const handleClose = (reload?: boolean) => {
    setIsOpen(false)
    setIsFinishing(false)
    setTaskEdit(undefined)
    if (reload) {
      init()
    }
  }
  const markAsDone = async () => {
    if (operation) {
      try {
        const re = await toggleDone(operation.id, operation?.status !== 3)
        if (!re.ok) {
          throw new Error()
        }
        toast("Uzdevums veigsmīgi labots")
      } catch (err) {
        toast("Neizdevās izpildīt darbību, lūdzu mēģini vēlreiz", {
          type: "error",
        })
        //
      }
    }
    init()
  }

  const handleHistoryEdit = (task: Task) => {
    setTaskEdit(task)
    setIsOpen(true)
  }

  const sortedHistory = useMemo(
    () =>
      history.sort((a, b) =>
        new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1,
      ),
    [history],
  )
  return (
    <>
      <FinishTask
        isFinishing={isFinishing}
        isOpen={isOpen}
        opId={operation?.id}
        handleClose={(reload) => handleClose(reload)}
        editTask={taskEdit}
      />
      <BreadCrumbs
        items={[
          { path: "/dashboard/", title: "Mani uzdevumi" },
          {
            path: `/dashboard/project/${projectInfo?.id}`,
            title: projectInfo?.name ?? "",
          },
          {
            path: `/dashboard/project/${projectInfo?.id}/${sub?.id}`,
            title: sub?.name ?? "",
          },
          {
            path: `/dashboard/project/${projectInfo?.id}/${sub?.id}/${operation?.id}`,
            title: operation?.name ?? "",
          },
        ]}
      />
      <div className={styles.title}>
        <Link to={`/dashboard/project/${params.projId}/${params.subId}`}>
          <ChevronLeft />
        </Link>
        <span>{operation?.name}</span>
      </div>
      <div className={styles.projectTitle}>
        {projectInfo?.name}
        <span>{projectInfo?.code}</span>
      </div>
      <div className={styles.projectSub}>
        {sub?.name}
        <span>{sub?.code}</span>
      </div>
      <StatusText status={operation?.status} />

      <div className={styles.blocks}>
        {features["units"] && (
          <div
            className={classNames(
              styles.taskBlock,
              operation?.status === 3 &&
                operation.unitsUsed < operation.unitsPredicted
                ? styles.errorBlock
                : undefined,
            )}
          >
            <div className={styles.taskRow}>
              <div className={styles.taskRowTitle}>
                {operation?.unitsUsed || 0}
              </div>
              <div className={styles.taskRowSub}>Vienības</div>
            </div>
            <div className={styles.taskRow}>
              <div className={styles.taskRowTitle}>
                {operation?.unitsPredicted || 0}
              </div>
              <div className={styles.taskRowSub}>Paredzētās vienības</div>
            </div>
          </div>
        )}
        {features["salary"] && (
          <div className={styles.taskBlock}>
            <div className={styles.taskRow}>
              <div className={styles.taskRowTitle}>
                {operation?.salaryUsed ? operation.salaryUsed / 100 : 0}€
              </div>
              <div className={styles.taskRowSub}>Nopelnītais</div>
            </div>
            <div className={styles.taskRow}>
              <div className={styles.taskRowTitle}>
                {operation?.salaryPredicted
                  ? operation.salaryPredicted / 100
                  : 0}
                €
              </div>
              <div className={styles.taskRowSub}>Summa</div>
            </div>
          </div>
        )}
        {features["time"] && (
          <div className={styles.taskBlock}>
            <div className={styles.taskRow}>
              <div className={styles.taskRowTitle}>
                {formatTimeDelta(operation?.timeUsed)}
              </div>
              <div className={styles.taskRowSub}>Reālais laiks</div>
            </div>
            <div className={styles.taskRow}>
              <div className={styles.taskRowTitle}>
                {formatTimeDelta(operation?.timePredicted)}
              </div>
              <div className={styles.taskRowSub}>Paredzētais laiks</div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.btnWrap}>
        {operation && operation?.status >= 3 ? null : (
          <Button onClick={() => setIsOpen(true)}>Pievienot</Button>
        )}
        <RegisterDefect
          taskId={params.taskId as unknown as Task["id"]}
          onSave={init}
        />
        <Button variant="secondary" onClick={markAsDone}>
          {operation && operation?.status >= 3
            ? "Atjaunot uzdevumu"
            : "Atzīmēt kā pabeigtu"}
        </Button>
      </div>
      {sortedHistory.length ? (
        <>
          <div className={styles.historyTitle}>Vēsture</div>
          {sortedHistory.map((task) => {
            return (
              <HistoryItem
                task={task}
                onEdit={handleHistoryEdit}
                key={task.id}
              />
            )
          })}
        </>
      ) : null}
    </>
  )
}
