import { prepareQuery } from "@/lib/prepareQuery"
import { doFetch } from "./fetch"
import { Project } from "src/types"

export const tasksApi = {
  getTasks: (filters: Record<string, any>) => {
    const q = prepareQuery(filters)
    return doFetch(`/api/views/tasks${q}`)
  },
  confirmTask: (
    taskId: number,
    body: {
      status: number
      timeUsed?: number
      unitsUsed?: number
      salaryUsed?: number
      sumUsed?: number
    },
  ) => {
    return doFetch(`/api/tasks/${taskId}`, { body, method: "POST" })
  },
  getProjectsViewData: (filters: Record<string, any>) => {
    const q = prepareQuery(filters)
    return doFetch(`/api/views/projects${q}`)
  },

  createProject: (body: Partial<Project> & { districtId?: number }) => {
    return doFetch(`/api/projects`, { body, method: "POST" })
  },
}
