import { HOST } from "src/config/urls"

type RequestInitWithObjectBody =
  | (Omit<RequestInit, "body"> & {
      body?: BodyInit | object
    })
  | RequestInit

export const doFetch = async (
  url: string,
  options?: RequestInitWithObjectBody,
) => {
  let body: BodyInit | null = null
  if (options?.body) {
    if (typeof options?.body === "object") {
      body = JSON.stringify(options.body)
    } else {
      body = options.body
    }
  }
  const res = await fetch(`${HOST}${url}`, {
    ...options,
    credentials: "include",
    body,
  })

  if (res.ok && res.status >= 200 && res.status <= 299) {
    if (res.status === 204) {
      return ""
    }
    const contenttype = res.headers.get("content-type")
    if (contenttype && contenttype.includes("application/json")) {
      return res.json()
    }
    if (contenttype && contenttype.includes("text/")) {
      return res.text()
    }
    return res.blob()
  }

  return Promise.reject({
    options,
    status: {
      url,
      code: res.status,
      message: res.statusText,
    },
  })
}
