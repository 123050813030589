export type Project = {
  id: number
  name: string
  code: string
  status: number
  subprojectCount: number
  subCount: number
  progress: number
  timeProgress: number
  unitsPredicted: number
  unitsUsed: number
  unitsDone: number
  unitsDiff: number
  salaryPredicted: number
  salaryUsed: number
  salaryDone: number
  salaryDiff: number
  timePredicted: number
  timeUsed: number
  timeDone: number
  timeDiff: number
  difficulty: number
  shift: number
  shiftsPerDay: number
  concurrentShifts: number
  workers: number
  parentId?: number
  parentName?: string
}

export enum TaskStatus {
  Created = 0,
  Approved = 1,
  StatusToStarted = 2,
  StatusToDone = 3,
  Error = 4,
  ErrorAck = 5,
}

export type Task = {
  target?: string
  id: number
  user: {
    id: number
    name: string
  }
  worker: {
    id: number
    name: string
  }
  workerName: string
  operationId: number
  operationName: string
  parentProject: {
    id: number
    name: string
    code: string
  }
  project: {
    id: number
    name: string
    code: string
  }
  projectName: string
  projectId: number
  subProjectId: number
  timePredicted: number
  subProjectName: string
  operation: {
    id: number
    name: string
    workerCount: number
  }
  timeUsed: number
  timeExpected: number
  date: number | string
  createdAt: number
  deletedAt: number
  unitsUsed: number
  opUnitsUsed?: number
  unitsPredicted: number
  expenseSum: number
  approved: false
  salaryUsed: number
  salaryPredicted: number
  salary: string
  status: TaskStatus
  comment: null | string
  normative: number
  defect: boolean
}
export type Group = {
  id: number
  name: string
  status: number
  operations: Operation[]
}

export type Operation = {
  createdAt: string
  id: number
  opGroup: {
    id: number
    name: string
    parentId: number
    createdAt: string
  }
  name: string
  opGroupId: number
  projectId: number
  status: number
  tenantId: number
  tenantUserId: number
  timePredicted: number
  timeUsed: number
  unitsPredicted: number
  unitsUsed: number
  salaryUsed: number
  salaryPredicted: number
  workerCountExpected: number
  workerCountUsed: number
}
export type District = {
  id: number
  tenantId: number
  name: string
  workers: number
  shift: number
  concurrentShifts: number
  shiftsPerDay: number
  status: number
  deletedAt: number
  createdAt: number
  updatedAt: number
}
export enum UserRole {
  Empty = 0,
  User = 1 << 0, // 1
  Admin = 1 << 1, // 2
  SuperAdmin = 1 << 2, // 4
}
export type PermissionItem = {
  id: number
  name: string
}
export type Worker = {
  id: number
  name: string
  email: string
  username: string
  coeficient: number
  errorCount: number
  role: UserRole
  changePasswordUrl?: string
  urlExpireAt?: string
  permissions: {
    projects: PermissionItem[]
    opGroups: PermissionItem[]
    districts?: PermissionItem[]
  }
}
