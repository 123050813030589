import { Redirect, Route, Switch } from "wouter"
import { AdminDashboard } from "./pages/admin/AdminDashboard/AdminDashboard"
import { Header } from "./components/header/Header"
import { Tasks } from "./pages/main/dashboard/Tasks"
import { ProjectView } from "./pages/main/projectView/ProjectView"
import { OpenProjectView } from "./pages/main/projectView/OpenProject"
import { ViewTask } from "./pages/main/projectView/task/ViewTask"
import { FeatureProvider } from "./providers/FeatureProvider"
import { UserView } from "./pages/main/user/UserView"
import { Worksheet } from "./pages/main/user/Worksheet"
import { AuthWrapper } from "./pages/auth"
import { AdminRoot } from "./pages/admin"

export const App = () => {
  // is logged in
  return (
    <AuthWrapper>
      <FeatureProvider />
      <Header />
      <Switch>
        <Route path="/admin/dashboard" component={AdminRoot} nest />
        <div
          style={{
            padding: "0 16px 60px",
            maxWidth: "800px",
            margin: "0 auto",
            height: "calc(100% - 54px)",
          }}
        >
          <Route path="/" component={Tasks} />
          <Route path="/me" component={UserView} />
          <Route path="/me/worksheet" component={Worksheet} />
          <Route path="/dashboard" component={Tasks} />
          <Route path="/dashboard/project/:id" component={ProjectView} />
          <Route
            path="/dashboard/project/:projId/:subId"
            component={OpenProjectView}
          />
          <Route
            path="/dashboard/project/:projId/:subId/:taskId"
            component={ViewTask}
          />
        </div>
        <Redirect to="/dashboard" />
        <Route>404</Route>
      </Switch>
    </AuthWrapper>
  )
}
