import { Control, FieldError, FieldPath, FieldValues } from "react-hook-form"
import { FormField, FormItem, FormLabel } from "../form"
import { ComponentProps } from "react"
import { Input } from "../input"

type Props<
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
> = {
  control: Control<T>
  name: TName
  placeholder?: string
  label?: string
  inputProps?: ComponentProps<typeof Input>
  error?: FieldError
}
export function InputField<T extends FieldValues = FieldValues>({
  control,
  name,
  placeholder,
  label,
  inputProps,
  error,
}: Props<T>) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          {label && <FormLabel>{label}</FormLabel>}
          <Input
            {...field}
            placeholder={placeholder}
            className={error ? "border-[var(--error)]" : ""}
            {...inputProps}
          />
        </FormItem>
      )}
    />
  )
}
